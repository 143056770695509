.contact-support-container .popover-title {
    font-weight: 700;
    font-size: 16px;
}

.contact-support-container .popover-content {
    padding: 9px;
}

.contact-support-container .popover {
    min-width: 450px;
}

.support-question {
    min-height: 100px;
}

#AttachmentFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

#AttachmentFile:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.contact-support-attachment-label {
    font: inherit;
    text-overflow: ellipsis;
    max-width: 200px;
    min-width: 70px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.contact-support-loader {
    display: flex;
    justify-content: center;
}